import { useEffect } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { es } from 'date-fns/locale';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Accordion from '@mui/material/Accordion';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { useRouter } from 'src/routes/hooks';

import { fNumber } from 'src/utils/format-number';
import { formatCedula, formatPhoneNumber } from 'src/utils/formatters';

import { useAuthContext } from 'src/auth/hooks';
import { PATIENT_STATUS_ICONS } from 'src/_mock/ENUMS';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import LogoMedicoreGrumeba from 'src/components/logo/logo-medicore-grumeba';

import ResultsDetails from './details/results-details';
import AdmissionDetails from './details/admission-details';

// ----------------------------------------------------------------------

PatientHomeAdmissions.propTypes = {
  patientRecord: PropTypes.object,
};

// ----------------------------------------------------------------------

const base64ToArrayBuffer = (base64) => {
  try {
    const cleanedBase64 = base64.replace(/^data:application\/pdf;base64,/, '');
    const binaryString = atob(cleanedBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  } catch (err) {
    throw new Error('Base64 no válida o malformada');
  }
};

export default function PatientHomeAdmissions({ patientRecord }) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  console.log(patientRecord);

  const { logout } = useAuthContext();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('¡No se pudo cerrar la sesión!', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (patientRecord.admissions.length > 0) {
      if (
        patientRecord.admissions.filter((admission) => admission.admissionCauseCreation === 0)
          .length > 0
      ) {
        patientRecord.admissions
          .filter((admission) => admission.admissionCauseCreation === 0)
          .forEach((admission) => {
            admission.admissionInvoices[0].medicalServices.forEach((service) => {
              if (service.admitionServiceMedia.fileType.includes('application/pdf')) {
                service.admitionServiceMedia.pdfurl = URL.createObjectURL(
                  new Blob([base64ToArrayBuffer(service.admitionServiceMedia.file)], {
                    type: 'application/pdf',
                  })
                );
              }
            });
          });
      }
    }
  }, [patientRecord]);

  const renderSummary = (
    <Card sx={{ py: 3, textAlign: 'center', typography: 'h4' }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
      >
        <Stack width={1}>
          {fNumber(patientRecord.admissions.length)}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            Admisiones
          </Box>
        </Stack>

        {patientRecord.appointments.length > 0 && (
          <Stack width={1}>
            {fNumber(patientRecord.appointments.length)}
            <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
              Consultas
            </Box>
          </Stack>
        )}
      </Stack>
    </Card>
  );

  const renderAbout = (
    <Card>
      <CardHeader title="Acerca del Paciente" />

      <Stack spacing={2} sx={{ p: 3 }}>
        {patientRecord.patient.documentNumber && (
          <Stack direction="row" sx={{ typography: 'body2' }}>
            <Iconify icon="ph:identification-card-fill" width={24} sx={{ mr: 2 }} />
            {formatCedula(patientRecord.patient.documentNumber)}
          </Stack>
        )}

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="ph:calendar-dots-fill" width={24} sx={{ mr: 2 }} />
          {format(new Date(patientRecord.patient.birth), 'dd MMMM yyyy', {
            locale: es,
          }).toUpperCase()}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Iconify icon="mingcute:location-fill" width={24} />

          <Box sx={{ typography: 'body2' }}>
            {`Vive en `}
            <Link variant="subtitle2" color="inherit">
              {`${patientRecord.patient.contactInfo.city} ${patientRecord.patient.contactInfo.province}`}
            </Link>
          </Box>
        </Stack>

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="ph:gender-intersex-bold" width={24} sx={{ mr: 2 }} />
          {patientRecord.patient.gender === 'M' ? 'Masculino' : 'Femenino'}
        </Stack>

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="mingcute:phone-fill" width={24} sx={{ mr: 2 }} />
          {formatPhoneNumber(patientRecord.patient.contactInfo.cellNumber)}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Iconify icon="material-symbols:e911-avatar-rounded" width={24} />

          <Box sx={{ typography: 'body2' }}>
            {`En caso de emergencia, llamar a `}
            <br />
            <Link variant="subtitle2" color="inherit">
              {patientRecord.patient.contactInfo.emergencyContactName}
              <br />
              {formatPhoneNumber(patientRecord.patient.contactInfo.emergencyContactPhone)}
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );

  const renderLastVisit = (
    <Card sx={{ p: 3 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <LogoMedicoreGrumeba width={85} height={85} lightMode={theme.palette.mode === 'light'} />

          <ListItemText
            sx={{
              ml: { md: 3 },
              textAlign: { xs: 'center', md: 'unset' },
              alignItems: 'center',
            }}
            primary="GRUMEBA - GRUPO MÉDICO BANÍ"
            secondary="Último Centro Médico Visitado"
            primaryTypographyProps={{
              typography: 'h4',
            }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'subtitle2',
              sx: { opacity: 0.8 },
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );

  const renderPatientStatusSummary = (
    <Card>
      <CardHeader title="Resumen general del paciente" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Box
          sx={{
            typography: 'body2',
            textAlign: 'justify',
            justifyContent: 'stretch',
          }}
        >
          Paciente con tipo de sangre O+ que padece de hipertensión y diabetes tipo 2 controlada.
          Presenta alergias documentadas a la penicilina y al látex. Actualmente bajo tratamiento
          con metformina 500mg y losartán 50mg diarios. Cuenta con antecedentes quirúrgicos de
          apendicectomía. No presenta complicaciones significativas y mantiene un control regular de
          sus condiciones crónicas.
        </Box>
      </Stack>
    </Card>
  );

  const renderPatientRepresentative = (
    <Card>
      <CardHeader title="Representante Adulto" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="ph:identification-card-fill" width={24} sx={{ mr: 2 }} />
          {formatCedula(patientRecord.representativePerson?.documentId)}
        </Stack>

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="ph:user-fill" width={24} sx={{ mr: 2 }} />
          {patientRecord.representativePerson?.name} {patientRecord.representativePerson?.lastName}
        </Stack>
        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="ph:calendar-dots-fill" width={24} sx={{ mr: 2 }} />
          {patientRecord.representativePerson &&
            format(new Date(patientRecord.representativePerson?.birth), 'dd MMMM yyyy', {
              locale: es,
            }).toUpperCase()}
        </Stack>

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="ph:gender-intersex-bold" width={24} sx={{ mr: 2 }} />
          {patientRecord.representativePerson?.gender === 'M' ? 'Masculino' : 'Femenino'}
        </Stack>

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="mingcute:phone-fill" width={24} sx={{ mr: 2 }} />
          {formatPhoneNumber(patientRecord.representativePerson?.phoneNumber)} <br />
          {formatPhoneNumber(patientRecord.representativePerson?.cellNumber)}
        </Stack>
      </Stack>
    </Card>
  );

  const renderAdmissionContent = (admission) => {
    if (admission.admissionMedicalRecord && admission.admissionCauseCreation !== 0) {
      return (
        <>
          <Typography variant="h6" sx={{ mb: 2, ml: 1 }}>
            Historia de la enfermedad actual
          </Typography>
          <AdmissionDetails admission={admission} />
        </>
      );
    }

    if (
      admission.admissionCauseCreation === 0 &&
      admission.admissionInvoices.filter((invoice) => invoice.medicalServices?.length > 0).length >
        0
    ) {
      return <ResultsDetails admission={admission.admissionInvoices[0]} />;
    }

    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Stack spacing={3}>
          {renderSummary}
          <Button onClick={handleLogout} variant="contained" color="error" size="large">
            <Iconify icon="mdi:logout" height={24} width={24} />
            <Typography variant="h6" sx={{ ml: 1 }}>
              Cerrar sesión
            </Typography>
          </Button>
          {renderAbout}

          {patientRecord.representativePerson && renderPatientRepresentative}
        </Stack>
      </Grid>

      <Grid xs={12} md={8}>
        <Stack spacing={3}>
          {renderLastVisit}

          {renderPatientStatusSummary}

          {patientRecord.admissions?.map((admission) => (
            <Card key={admission.adminsionId}>
              <Accordion key={admission.adminsionId} disabled={false}>
                <AccordionSummary
                  expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    ml: 1,
                    mr: 1,
                    '& .MuiAccordionSummary-content': {
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    },
                  }}
                >
                  {admission.admissionCauseCreation !== 0 && (
                    <Iconify
                      icon={PATIENT_STATUS_ICONS[admission.admissionCauseCreation].icon}
                      width={30}
                      sx={{
                        color: PATIENT_STATUS_ICONS[admission.admissionCauseCreation].colorHex,
                      }}
                    />
                  )}

                  {admission.admissionCauseCreation === 0 &&
                    admission.admissionInvoices.filter(
                      (invoice) => invoice.medicalServices?.length > 0
                    ).length > 0 && (
                      <Iconify
                        icon="healthicons:biochemistry-laboratory"
                        width={35}
                        sx={{ color: '#2196F3' }}
                      />
                    )}
                  {admission.admissionCauseCreation !== 0 && (
                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                      Visita por {PATIENT_STATUS_ICONS[admission.admissionCauseCreation].label}
                    </Typography>
                  )}
                  {admission.admissionCauseCreation === 0 &&
                    admission.admissionInvoices.filter(
                      (invoice) => invoice.medicalServices?.length > 0
                    ).length > 0 && (
                      <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                        Consulta de Resultados
                      </Typography>
                    )}
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      mr: { xs: 1.5, md: 2.5 },
                      textAlign: 'right',
                    }}
                  >
                    {format(new Date(admission.createDate), 'dd MMMM yyyy', {
                      locale: es,
                    }).toUpperCase()}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: -1, ml: 1, pr: 4, width: '100%' }}>
                  {renderAdmissionContent(admission)}
                </AccordionDetails>
              </Accordion>
            </Card>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
