import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import RoleBasedGuard from 'src/auth/guard/role-based-guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const PatientListView = lazy(() => import('src/pages/medicore/patient/list'));
const CreatePatientPage = lazy(() => import('src/pages/medicore/patient/new'));
const EditPatientPage = lazy(() => import('src/pages/medicore/patient/edit'));
const Prueba = lazy(() => import('src/pages/medicore/rawing/prueba'));

// ----------------------------------------------------------------------

export const patientRoutes = [
  {
    path: 'patient',
    element: (
      <AuthGuard>
        <RoleBasedGuard roles={['doctor', 'employee']}>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RoleBasedGuard>
      </AuthGuard>
    ),
    children: [
      { element: <PatientListView />, index: true },
      { path: 'new', element: <CreatePatientPage /> },
      { path: 'edit/:id', element: <EditPatientPage /> },
      { path: 'list', element: <PatientListView /> },
      { path: 'rawing', element: <Prueba /> },
    ],
  },
];
