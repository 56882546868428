import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';

import { ChangePassword } from 'src/services/user/user-service-calls';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();

  const password = useBoolean();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('La contraseña antigua es requerida'),
    newPassword: Yup.string()
      .required('La nueva contraseña es requerida')
      .min(6, 'La contraseña debe tener al menos 6 caracteres')
      .test(
        'no-match',
        'La nueva contraseña debe ser diferente a la antigua',
        (value, { parent }) => value !== parent.oldPassword
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('newPassword')],
      'Las contraseñas deben coincidir'
    ),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await ChangePassword(data);
      enqueueSnackbar('¡Actualización exitosa!');
      reset();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error === 'Old password is incorrect'
          ? 'Contraseña antigua incorrecta'
          : 'Error al actualizar la contraseña',
        { variant: 'error' }
      );
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        <RHFTextField
          name="oldPassword"
          type={password.value ? 'text' : 'password'}
          label="Contraseña Antigua"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="newPassword"
          type={password.value ? 'text' : 'password'}
          label="Contraseña Nueva"
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={
            <Stack component="span" direction="row" alignItems="center">
              <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> La contraseña debe tener
              mínimo 6+ caracteres
            </Stack>
          }
        />

        <RHFTextField
          name="confirmPassword"
          type={password.value ? 'text' : 'password'}
          label="Confirmar Contraseña Nueva"
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ ml: 'auto' }}>
          Guardar Cambios
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
