import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from 'src/theme/css';

// ----------------------------------------------------------------------

PatientGeneralInfoCover.propTypes = {
  patientRecord: PropTypes.object,
  coverUrl: PropTypes.string,
};

// ----------------------------------------------------------------------

export default function PatientGeneralInfoCover({ patientRecord, coverUrl }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.primary.darker, 0.98),
          imgUrl: coverUrl,
        }),
        height: 1,
        color: 'common.white',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 30 },
          bottom: { md: 70 },
          zIndex: { md: 10 },
          pt: { xs: 9, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        <ListItemText
          sx={{
            ml: { xs: 3, md: 3 },
            mr: { xs: 3, md: 0 },
            textAlign: { xs: 'center', md: 'unset' },
          }}
          primary={`${patientRecord.patient.name} ${patientRecord.patient.lastName}`}
          secondary="PACIENTE DE GRUMEBA - GRUPO MÉDICO BANÍ"
          primaryTypographyProps={{
            typography: 'h3',
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            color: 'inherit',
            component: 'span',
            typography: 'subtitle2',
            sx: { opacity: 0.48 },
          }}
        />
      </Stack>
    </Box>
  );
}
