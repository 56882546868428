import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import Logo from 'src/components/logo/logo-mini';
import { useSettingsContext } from 'src/components/settings';

import { NAV, HEADER } from '../config-layout';

// ----------------------------------------------------------------------

export default function Main({ children, sx, ...other }) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 2,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 2,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: `${HEADER.H_MOBILE}px`,
        pb: 2,
        ...(lgUp && {
          pt: `${HEADER.H_DESKTOP}px`,
          pb: 2,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
      <Box
        component="footer"
        sx={{
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default',
          bottom: 0,
          mt: 7,
        }}
      >
        <Container>
          <Typography
            variant="caption"
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.2,
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            © {new Date().getFullYear()}
            <Logo sx={{ width: 25, height: 25 }} />
            Medicore®. Todos los derechos reservados.
          </Typography>
          <Typography variant="caption" component="div">
            Hecho por
            <Link href="https://nexuscore.com.do/"> nexus core crh </Link>
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
