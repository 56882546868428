import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import { useBoolean } from 'src/hooks/use-boolean';

import { getPatientDigitalReport } from 'src/services/patient/patient-service-calls';

import { useSnackbar } from 'src/components/snackbar';
import { SplashScreen } from 'src/components/loading-screen';

import PatientPortalDigitalReport from '../patient-portal-digital-report';

// ----------------------------------------------------------------------

export default function PatientPortalDigitalReportView() {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [patientDigitalReport, setPatientDigitalReport] = useState(null);

  const loading = useBoolean(true);

  const fetchPatientDigitalReport = useCallback(async () => {
    try {
      loading.onTrue();
      const response = await getPatientDigitalReport(id);
      setPatientDigitalReport(response);
      loading.onFalse();
    } catch (error) {
      console.error('Error fetching patient digital report:', error);
      enqueueSnackbar(error, { variant: 'error' });
      loading.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchPatientDigitalReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return loading.value ? (
    <SplashScreen />
  ) : (
    <PatientPortalDigitalReport patientDigitalReport={patientDigitalReport} />
  );
}
