import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { es } from 'date-fns/locale';

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import { alpha } from '@mui/material/styles';
import TimelineDot from '@mui/lab/TimelineDot';
import Grid from '@mui/material/Unstable_Grid2';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { Box, Stack, useTheme, Typography } from '@mui/material';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { fDate, fTime } from 'src/utils/format-time';

import { PATIENT_STATUS_ICONS } from 'src/_mock/ENUMS';

import Iconify from 'src/components/iconify';

AdmissionDetails.propTypes = {
  admission: PropTypes.object,
};

export default function AdmissionDetails({ admission }) {
  const theme = useTheme();

  const symptomsBodyLocation = JSON.parse(admission.admissionMedicalRecord.symptomsBodyLocation);
  const irradation = admission.admissionMedicalRecord.irradation
    ? JSON.parse(admission.admissionMedicalRecord.irradation)
    : null;

  const otherSymtoms = admission.admissionMedicalRecord.otherSymtoms
    ? JSON.parse(admission.admissionMedicalRecord.otherSymtoms)
    : null;

  return (
    <Grid container gap={2} sx={{ width: '100%' }}>
      <Grid xs={12}>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: alpha(
              theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
              0.4
            ),

            p: 1.5,
            pl: 2,
          }}
        >
          <Stack direction="column" gap={0.5}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Motivo de admisión (CIE-10)
            </Typography>
            <Typography variant="body">{admission.admissionMedicalRecord.icd}</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid xs={12} md={12} sm={12}>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Fecha de admisión
              </Typography>
              <Typography variant="body">
                {format(new Date(admission.createDate), 'dd MMMM yyyy', {
                  locale: es,
                }).toUpperCase()}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Hora de admisión
              </Typography>
              <Typography variant="body">
                {format(new Date(admission.createDate), 'hh:mm a', {
                  locale: es,
                }).toUpperCase()}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>

      <Grid xs={12} md={12} sm={12}>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Fecha de alta
              </Typography>
              <Typography variant="body">
                {format(
                  new Date(admission.admissionHistory.filter((h) => h.action === 9)[0].createdDate),
                  'dd MMMM yyyy',
                  {
                    locale: es,
                  }
                ).toUpperCase()}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Hora de alta
              </Typography>
              <Typography variant="body">
                {format(
                  new Date(admission.admissionHistory.filter((h) => h.action === 9)[0].createdDate),
                  'hh:mm a',
                  {
                    locale: es,
                  }
                ).toUpperCase()}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>

      <Grid xs={12} md={12} sm={12}>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(3, 1fr)">
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Temperatura corporal
              </Typography>
              <Typography variant="body">
                {admission.admissionMedicalRecord.bodyTemperature} ºC
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Presión sistólica
              </Typography>
              <Typography variant="body">
                {admission.admissionMedicalRecord.bloodPressureSystolic} mmHg
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Presión diastólica
              </Typography>
              <Typography variant="body">
                {admission.admissionMedicalRecord.bloodPressureDiastolic} mmHg
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>

      <Grid xs={12} md={12} sm={12}>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={1} sx={{ mt: 1.1 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Fecha de inicio de síntomas
              </Typography>
              <Typography variant="body">
                {format(new Date(admission.admissionMedicalRecord.dateSymtoms), 'dd MMMM yyyy', {
                  locale: es,
                }).toUpperCase()}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Escala del dolor
              </Typography>

              <Typography variant="body">{admission.admissionMedicalRecord.intensity}</Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                1 (Leve) - 10 (Severo)
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} md={12} sm={12}>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="column" gap={1}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Localización de síntomas
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {symptomsBodyLocation.map((location) => (
                  <Chip key={location} label={location.toUpperCase()} variant="soft" />
                ))}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Grid>
      {irradation && (
        <Grid xs={12} md={12} sm={12}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
            <Box
              sx={{
                borderRadius: 2,
                backgroundColor: alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                  0.4
                ),

                p: 1.5,
                pl: 2,
              }}
            >
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Irradiación
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {irradation.map((location) => (
                    <Chip key={location} label={location.toUpperCase()} variant="soft" />
                  ))}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}

      {admission.admissionMedicalRecord.triggeringRelievingFacts && (
        <Grid xs={12} md={12} sm={12}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
            <Box
              sx={{
                borderRadius: 2,
                backgroundColor: alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                  0.4
                ),

                p: 1.5,
                pl: 2,
              }}
            >
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Factores desencadenantes
                </Typography>
                <Typography variant="body">
                  <div>
                    {admission.admissionMedicalRecord.triggeringRelievingFacts.toUpperCase()}
                  </div>
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}
      {otherSymtoms && (
        <Grid xs={12} md={12} sm={12}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
            <Box
              sx={{
                borderRadius: 2,
                backgroundColor: alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                  0.4
                ),

                p: 1.5,
                pl: 2,
              }}
            >
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Síntomas adicionales
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {otherSymtoms.map((symptom, index) => (
                    <Chip key={index} label={symptom} variant="soft" />
                  ))}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}
      {admission.admissionMedicalRecord.observations && (
        <Grid xs={12} md={12} sm={12}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
            <Box
              sx={{
                borderRadius: 2,
                backgroundColor: alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                  0.4
                ),

                p: 1.5,
                pl: 2,
              }}
            >
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Observaciones
                </Typography>
                <Typography
                  variant="body"
                  sx={{ mt: -2, mb: -2 }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      admission.admissionMedicalRecord.observations.toUpperCase()
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}
      {admission.admissionMedicalRecord.pastMedicalHistory && (
        <Grid xs={12} md={12} sm={12}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
            <Box
              sx={{
                borderRadius: 2,
                backgroundColor: alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                  0.4
                ),

                p: 1.5,
                pl: 2,
              }}
            >
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Antecedentes médicos
                </Typography>
                <Typography
                  variant="body"
                  sx={{ mt: -2, mb: -2 }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      admission.admissionMedicalRecord.pastMedicalHistory.toUpperCase()
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}
      {admission.admissionMedicalRecord.allergies && (
        <Grid xs={12} md={12} sm={12}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
            <Box
              sx={{
                borderRadius: 2,
                backgroundColor: alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                  0.4
                ),

                p: 1.5,
                pl: 2,
              }}
            >
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Alergias
                </Typography>
                <Typography
                  variant="body"
                  sx={{ mt: -2, mb: -2 }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      admission.admissionMedicalRecord.allergies.toUpperCase()
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}
      {admission.admissionMedicalRecord.treatmentPlan && (
        <Grid xs={12} md={12} sm={12}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
            <Box
              sx={{
                borderRadius: 2,
                backgroundColor: alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                  0.4
                ),

                p: 1.5,
                pl: 2,
              }}
            >
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Tratamiento previo
                </Typography>
                <Typography
                  variant="body"
                  sx={{ mt: -2, mb: -2 }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      admission.admissionMedicalRecord.treatmentPlan.toUpperCase()
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}

      <Grid xs={12} md={12} sm={12}>
        <Typography variant="h6" sx={{ mb: 2, ml: 1 }}>
          Cronología
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
          <Stack direction="column" spacing={3} alignItems="center" width="100%">
            <Timeline position="alternate" style={{ width: '100%' }}>
              {admission.admissionHistory.map((item, index) => (
                <TimelineItem key={item.id}>
                  <TimelineOppositeContent>
                    <Stack direction="column" spacing={0.1}>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {PATIENT_STATUS_ICONS[item.action].label.toUpperCase()}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {fDate(item.createdDate).toUpperCase()}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {fTime(item.createdDate)}
                      </Typography>
                    </Stack>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color={PATIENT_STATUS_ICONS[item.action].color}>
                      <Iconify icon={PATIENT_STATUS_ICONS[item.action].icon} width={24} />
                    </TimelineDot>
                    {admission.admissionHistory.length - 1 !== index && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent sx={{ width: '100%' }}>
                    <Paper
                      sx={{
                        p: 3,
                        bgcolor: alpha(theme.palette.grey[500], 0.12),
                        width: '100%',
                      }}
                    >
                      {item.action === 9 && (
                        <Typography variant="body2" sx={{ color: 'text.secondary', width: '100%' }}>
                          {item.description
                            ? item.description.toUpperCase()
                            : 'AUTORIZACIÓN DE ALTA MÉDICA.'}
                        </Typography>
                      )}
                      {item.action !== 9 && (
                        <Typography variant="body2" sx={{ color: 'text.secondary', width: '100%' }}>
                          {item.description.toUpperCase()}
                        </Typography>
                      )}
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Stack>
        </Box>
      </Grid>

      {admission.patientDischarged.dischargePrescription && (
        <>
          <Grid xs={12} md={12} sm={12}>
            <Typography variant="h6" sx={{ ml: 1 }}>
              Detalles de Alta Médica
            </Typography>
          </Grid>
          {admission.patientDischarged.dischargeSummary && (
            <Grid xs={12} md={12} sm={12}>
              <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
                <Box
                  sx={{
                    borderRadius: 2,
                    backgroundColor: alpha(
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[400]
                        : theme.palette.grey[700],
                      0.4
                    ),

                    p: 1.5,
                    pl: 2,
                  }}
                >
                  <Stack direction="column" gap={1}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Resumen de alta
                    </Typography>
                    <Typography
                      variant="body"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          admission.patientDischarged.dischargeSummary.toUpperCase()
                        ),
                      }}
                    />
                  </Stack>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid xs={12} md={12} sm={12}>
            <Box display="grid" gap={2} gridTemplateColumns="repeat(1, 1fr)">
              <Box
                sx={{
                  borderRadius: 2,
                  backgroundColor: alpha(
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[400]
                      : theme.palette.grey[700],
                    0.4
                  ),

                  p: 1.5,
                  pl: 2,
                }}
              >
                <Stack direction="column" gap={1}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Receta de alta
                  </Typography>
                  <Typography
                    variant="body"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        admission.patientDischarged.dischargePrescription.toUpperCase()
                      ),
                    }}
                  />
                </Stack>
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}
