import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import { _userAbout } from 'src/_mock';
import AccountChangePassword from 'src/shared/common/account-change-password';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';

import PatientHomeAdmissions from './patient-digital-record-profile/patient-home-admissions';
import PatientGeneralInfoCover from './patient-digital-record-profile/patient-general-info-cover';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'record',
    label: 'Historial',
    icon: <Iconify icon="solar:history-2-bold-duotone" width={24} />,
  },
  {
    value: 'account',
    label: 'Cuenta',
    icon: <Iconify icon="solar:shield-user-bold-duotone" width={24} />,
  },
];

PatientPortalDigitalReportView.propTypes = {
  patientDigitalReport: PropTypes.object,
};

// ----------------------------------------------------------------------

export default function PatientPortalDigitalReportView({ patientDigitalReport }) {
  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('record');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Card
        sx={{
          mb: 3,
          height: 290,
        }}
      >
        <PatientGeneralInfoCover
          patientRecord={patientDigitalReport}
          coverUrl={_userAbout.coverUrl}
        />

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            textAlign: 'center',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                xs: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>

      {currentTab === 'record' && <PatientHomeAdmissions patientRecord={patientDigitalReport} />}

      {currentTab === 'account' && <AccountChangePassword />}
    </Container>
  );
}
