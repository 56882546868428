import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  createEmployee,
  updateEmployee,
} from 'src/services/humanResources/employees-service-calls';

import FormProvider, { RHFSelect, RHFUpload, RHFTextField } from 'src/components/hook-form';

import { getJobPositions } from '../../../services/humanResources/job-positions-service-calls';

// ----------------------------------------------------------------------

const optionsStatus = [
  { value: 'A', label: 'Activo' },
  { value: 'I', label: 'Inactivo' },
];

const optionsDocumentTypes = [
  { value: 0, label: 'Pasaporte' },
  { value: 1, label: 'Cedula' },
];

const optionsGender = [
  { value: 'm', label: 'Masculino' },
  { value: 'f', label: 'Femenino' },
];

export default function EmployeeNewEditForm({
  currentEmployee,
  openCloseModal,
  reset: resetEmployee,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [jobPositions, setJobPositionOptions] = useState([]);

  const getJobPositionOptions = useCallback(async () => {
    getJobPositions().then((response) => {
      const newOptions = response.data.map((x) => ({
        value: x.jobInfoId,
        label: x.jobPosition,
      }));

      setJobPositionOptions([...newOptions]);
    });
  }, []);

  useEffect(() => {
    getJobPositionOptions();
  }, [getJobPositionOptions]);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Nombre es requerido'),
    lastname: Yup.string().required('Apellido es requerido'),
    email: Yup.string().required('Correo es requerido').email('Favor ingrese un formato de correo'),
    birth: Yup.date().required('Nacimiento es requerido'),
    documentId: Yup.string().required('Numero De Identificacion es requerido'),
    documentType: Yup.number().required('Seleccionar docuemnto es requerido'),
    gender: Yup.string().required('Seleccionar Genero es requerido'),
    status: Yup.string().required('El estado es requerido'),
    cellPhoneNumer: Yup.string().required('Celular es requerido'),
    maritalStatus: Yup.string().required('Estado Civil es requerido'),
    address: Yup.string().required('Direccion es requerido'),
    emergencyContactPhone: Yup.string().required('Telefono de Emergencia es requerido'),
    emergencyContactName: Yup.string().required('Nombre de Emergencia es requerido'),
    // hireDate: Yup.date().required('Fecha de Contratación es requerido'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentEmployee?.name || '',
      lastname: currentEmployee?.lastname || '',
      email: currentEmployee?.email || '',
      birth: currentEmployee?.birth || null,
      documentId: currentEmployee?.documentId || '',
      documentType: currentEmployee?.documentType || undefined,
      gender: currentEmployee?.gender || '',
      status: currentEmployee?.status || '',
      cellPhoneNumer: currentEmployee?.cellPhoneNumer || '',
      maritalStatus: currentEmployee?.maritalStatus || '',
      emergencyContactPhone: currentEmployee?.emergencyContactPhone || '',
      emergencyContactName: currentEmployee?.emergencyContactName || '',
      // hireDate: currentEmployee?. hireDate|| null,
    }),
    [currentEmployee]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const { setValue } = methods;

  const handleDropSingleFilePhoto = useCallback(
    (acceptedFiles) => {
      try {
        // setIsProcessing(true);
        const file = acceptedFiles[0];

        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        if (newFile) {
          setValue('documentId', newFile, { shouldValidate: true });
        }

        // setIsProcessing(false);
      } catch (e) {
        console.error(e);
        // setIsProcessing(false);
      }
    },
    [setValue]
  );

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (currentEmployee) {
        const response = await updateEmployee(currentEmployee?.userId, data);

        if (response.status === 200) {
          enqueueSnackbar('¡Registro actualizado con exito!');
          resetEmployee();
          // refreshData();
          reset();
        } else {
          enqueueSnackbar('¡Error al actualizar el empleado!', { variant: 'error' });
        }
      } else {
        const response = await createEmployee(data);

        if (response.status === 200) {
          enqueueSnackbar('¡Empleado registrado con exito!');
          resetEmployee();
          // refreshData();
          reset();
        } else {
          enqueueSnackbar('¡Error al crear el empleado!', { variant: 'error' });
        }
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack direction="row">
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="name" label="Nombre" />
                <RHFTextField name="lastname" label="Apellido" />

                <Controller
                  name="birth"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      label="Fecha De Nacimiento"
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />

                <RHFSelect name="gender" label="Selecciona Genero">
                  <MenuItem value={undefined}>Ninguno</MenuItem>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {optionsGender.map((option) => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect name="documentType" label="Selecciona Documento">
                  <MenuItem value={undefined}>Ninguno</MenuItem>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {optionsDocumentTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect name="status" label="Estado">
                  <MenuItem value="">Ninguno</MenuItem>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {optionsStatus.map((option) => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect name="jobPositionId" label="Posición de Trabajo">
                  <MenuItem value="">Ninguno</MenuItem>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {jobPositions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <RHFTextField name="cellPhoneNumer" label="Celular" />
                <RHFTextField name="email" label="Correo" />
                <RHFTextField name="address" label="Direccion" />
                <RHFTextField name="maritalStatus" label="Estado Civil" />
                <RHFTextField name="emergencyContactName" label="Nombre Conctato de Emergencia" />
                <RHFTextField name="emergencyContactPhone" label="Telefono de Emergencia" />
              </Box>
              <Stack sx={{ marginLeft: '2rem' }} justifyContent="center">
                <Typography sx={{ pb: 1 }}>Agregar Foto de la cédula: </Typography>
                <RHFUpload name="documentId" onDrop={handleDropSingleFilePhoto} maxSize={3000000} />
              </Stack>
            </Stack>

            <Stack justifyContent="flex-end" direction="row" sx={{ mt: 3, mb: 3 }} spacing={2}>
              <Button
                onClick={() => {
                  openCloseModal?.onFalse();
                  reset();
                }}
                variant="contained"
                color="error"
              >
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                color="success"
              >
                {!currentEmployee ? 'Crear' : 'Guardar Cambios'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

EmployeeNewEditForm.propTypes = {
  currentEmployee: PropTypes.object,
  openCloseModal: PropTypes.object,
  reset: PropTypes.func,
};
