// ----------------------------------------------------------------------

export const GENERAL_STATUS = [
  { label: 'Activo', value: 0 },
  { label: 'Inactivo', value: 1 },
  { label: 'Usado', value: 2 },
  { label: 'Parcial', value: 3 },
  { label: 'Inicializado', value: 4 },
  { label: 'Cerrado', value: 5 },
  { label: 'Paciente Incompleto', value: 6 },
  { label: 'Disponible', value: 7 },
  { label: 'Pendiente', value: 8 },
  { label: 'Aprobado', value: 9 },
  { label: 'Cancelado', value: 10 },
  { label: 'Problema', value: 11 },
  { label: 'Procesando', value: 12 },
  { label: 'Completado', value: 13 },
  { label: 'Glosa', value: 14 },
];

export const PATIENT_STATUS = [
  'Ambulatorio',
  'Emergencia',
  'Internamiento',
  'Internamiento',
  'Creado',
  'Actualizado',
  'Admitido',
  'Procesando',
  'Detenido',
  'Alta',
  'Medicado',
  'Cuidados Intensivos',
];

export const PATIENT_STATUS_VALUES = [
  { label: 'Ambulatorio', value: 0 },
  { label: 'Emergencia', value: 1 },
  { label: 'Internamiento', value: 2 },
  { label: 'Internamiento', value: 3 },
  { label: 'Creado', value: 4 },
  { label: 'Actualizado', value: 5 },
  { label: 'Admitido', value: 6 },
  { label: 'Procesando', value: 7 },
  { label: 'Detenido', value: 8 },
  { label: 'Alta', value: 9 },
  { label: 'Medicado', value: 10 },
  { label: 'Cuidados Intensivos', value: 11 },
];

export const DOCNAME = [
  { label: 'Cédula Posterior', value: 'cardIdBack' },
  { label: 'Cédula Frontal', value: 'cardIdFront' },
  { label: 'Seguro Médico Posterior', value: 'insuranceBack' },
  { label: 'Seguro Médico Frontal', value: 'insuranceFront' },
];

export const PATIENT_STATUS_ICONS = [
  {
    label: 'Ambulatorio',
    icon: 'streamline:good-health-and-well-being-solid',
    color: 'info',
    colorHex: '#2196F3',
  },
  { label: 'Emergencia', icon: 'ic:round-emergency', color: 'error', colorHex: '#FF5252' },
  { label: 'Internamiento', icon: 'healthicons:inpatient', color: 'warning', colorHex: '#FF9800' },
  { label: 'Internamiento', icon: 'healthicons:inpatient', color: 'warning', colorHex: '#FF9800' },
  { label: 'Creado', icon: 'material-symbols:inpatient', color: 'success', colorHex: '#4CAF50' },
  {
    label: 'Actualizado',
    icon: 'icon-park-solid:update-rotation',
    color: 'secondary',
    colorHex: '#9E9E9E',
  },
  {
    label: 'Admitido',
    icon: 'icon-park-outline:hospital-bed',
    color: 'success',
    colorHex: '#4CAF50',
  },
  {
    label: 'Procesando',
    icon: 'icon-park-solid:loading-three',
    color: 'info',
    colorHex: '#2196F3',
  },
  { label: 'Detenido', icon: 'akar-icons:stop-fill', color: 'warning', colorHex: '#FF9800' },
  {
    label: 'Alta Médica',
    icon: 'healthicons:health-data-security',
    color: 'success',
    colorHex: '#4CAF50',
  },
  { label: 'Medicado', icon: 'fa6-solid:pills', color: 'primary', colorHex: '#3F51B5' },
  {
    label: 'Cuidados Intensivos',
    icon: 'medical-icon:intensive-care',
    color: 'error',
    colorHex: '#FF5252',
  },
];

export const ROOM_TYPE = ['Emergencia', 'Internamiento', 'Ambulatorio', 'UCI', 'Quirófano'];

export const ShiftProductRequestStatus = ['Pendiente', 'Parcial', 'Completo', 'Cerrado'];

export const SERVICE_TYPE = [
  { label: 'Laboratorios', value: 0 },
  { label: 'Imágenes Médicas', value: 1 },
];

export const FIXED_ASSETS_CONDITION = [
  { label: 'Nuevo', value: 0 },
  { label: 'Usado', value: 1 },
  { label: 'Reacondicionado', value: 2 },
  { label: 'Dañado', value: 3 },
  { label: 'En Reparación', value: 4 },
  { label: 'Obsoleto', value: 5 },
  { label: 'Desmantelado', value: 6 },
  { label: 'Alquilado', value: 7 },
  { label: 'Descontinuado', value: 8 },
];
