import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Stack, useTheme, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';
import { useFileHandler } from 'src/hooks/use-file-handler';

import Image from 'src/components/image';
import Icon from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';

ResultsDetails.propTypes = {
  admission: PropTypes.object,
};

export default function ResultsDetails({ admission }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { downloadFile } = useFileHandler();

  const mdUp = useResponsive('up', 'md');

  const handleDownload = (service) => {
    const { file, fileType } = service.admitionServiceMedia;
    const fileName = `${service.serviceName.toLowerCase()}${
      service.type === 'medicalImages' ? `_image_${service.index + 1}` : '_resumen'
    }`;

    const result = downloadFile(file, fileType, fileName);

    if (!result.success) {
      enqueueSnackbar(result.message, { variant: 'error' });
    } else {
      enqueueSnackbar('Archivo descargado exitosamente', { variant: 'success' });
    }
  };

  return (
    <Grid container spacing={3} sx={{ width: '100%' }}>
      {admission.medicalServices.map((service, index) => (
        <Grid xs={12} md={12} sm={12} key={index}>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack direction="row" alignItems="center" pl={2}>
              <Typography variant="h6">{service.serviceName}</Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: alpha(
                theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700],
                0.4
              ),

              p: 1.5,
              pl: 2,
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent={{ xs: 'center', sm: 'space-between' }}
              alignItems={{ xs: 'center', sm: 'flex-start' }}
              mb={2}
            >
              <Typography variant="h6" pl={2}>
                Resumen General
              </Typography>
              <Button
                variant={mdUp ? 'text' : 'contained'}
                color="inherit"
                disabled={!service.admitionServiceMedia.file}
                onClick={() => handleDownload(service)}
                sx={{
                  alignSelf: { xs: 'center', sm: 'flex-start' },
                  pl: { xs: 1, sm: 1 },
                }}
              >
                <Icon icon="mdi:download" width={20} height={20} />
                <Typography variant="caption" pl={1} sx={{ fontWeight: 'bold' }}>
                  Descargar Resumen
                </Typography>
              </Button>
            </Stack>
            {service.admitionServiceMedia.fileType.includes('image') && (
              <Stack
                direction="column"
                spacing={3}
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Image
                  key={1}
                  alt="GeneralSummary"
                  sx={{
                    borderRadius: 2,
                    width: '100%',
                    height: 'auto',
                    maxWidth: { xs: '100%', sm: '90%', md: '85%' },
                    minHeight: { xs: 'auto', sm: 400, md: 500 },
                    objectFit: 'contain',
                  }}
                  src={`data:${service.admitionServiceMedia.fileType};base64, ${service.admitionServiceMedia.file}`}
                />
              </Stack>
            )}
            {service.admitionServiceMedia.fileType.includes('application/pdf') &&
              service.admitionServiceMedia?.pdfurl && (
                <object
                  data={service.admitionServiceMedia.pdfurl}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  style={{
                    minHeight: '90vh',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    paddingLeft: 5,
                    paddingRight: 4,
                  }}
                >
                  <p>
                    El visor de PDF no es compatible con su navegador. Puede descargar el PDF{' '}
                    <a href={service.admitionServiceMedia?.pdfurl}>aquí</a>.
                  </p>
                </object>
              )}
            {service.medicalImages.length > 0 && (
              <>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  pb={3}
                  pt={3}
                  spacing={2}
                  justifyContent={{ xs: 'center', sm: 'space-between' }}
                  alignItems={{ xs: 'center', sm: 'flex-start' }}
                >
                  <Typography variant="h4" pl={2}>
                    Imágenes Médicas del {service.serviceName.toLowerCase()}
                  </Typography>
                  <Button
                    variant={mdUp ? 'text' : 'contained'}
                    color="inherit"
                    disabled={!service.medicalImages.length}
                    onClick={() =>
                      service.medicalImages.forEach((image, i) => {
                        handleDownload({
                          admitionServiceMedia: {
                            file: image.file,
                            fileType: image.fileType,
                          },
                          serviceName: service.serviceName,
                          type: 'medicalImages',
                          index: i,
                        });
                      })
                    }
                    sx={{
                      alignSelf: { xs: 'center', sm: 'flex-start' },
                      pl: { xs: 1, sm: 1 },
                    }}
                  >
                    <Icon icon="mdi:download" width={20} height={20} />
                    <Typography variant="subtitle" pl={1}>
                      Descargar imágenes
                    </Typography>
                  </Button>
                </Stack>
                <Stack
                  direction="column"
                  spacing={3}
                  justifyContent="center"
                  width="100%"
                  alignItems="center"
                >
                  {service.medicalImages.map((image) => (
                    <Image
                      key={image.mediaId}
                      alt="MedicalImages"
                      sx={{
                        borderRadius: 2,
                        width: '100%',
                        height: 'auto',
                        maxWidth: { xs: '100%', sm: '90%', md: '85%' },
                        minHeight: { xs: 'auto', sm: 'auto', md: 'auto' },
                        objectFit: 'contain',
                      }}
                      src={`data:${image.fileType};base64, ${image.file}`}
                    />
                  ))}
                </Stack>
              </>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
