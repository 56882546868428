/**
 * Formats a phone number string to either (999) 999-9999 or 9 (999) 999-9999
 * @param {string} phone - The phone number to format
 * @returns {string} - Formatted phone number or original string if invalid
 */
export const formatPhoneNumber = (phone) => {
  if (!phone) return '';

  // Remove any non-digit character
  const cleanedPhone = phone.replace(/\D/g, '');

  // Check length and apply corresponding format
  if (cleanedPhone.length === 10) {
    return `(${cleanedPhone.slice(0, 3)}) ${cleanedPhone.slice(3, 6)}-${cleanedPhone.slice(6)}`;
  }

  if (cleanedPhone.length === 11) {
    return `${cleanedPhone.slice(0, 1)} (${cleanedPhone.slice(1, 4)}) ${cleanedPhone.slice(
      4,
      7
    )}-${cleanedPhone.slice(7)}`;
  }

  // Return original string if it doesn't match expected lengths
  return phone;
};
