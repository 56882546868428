import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import Header from '../common/header-simple';
import FooterCompact from '../main/footer-compact';
// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  return (
    <Stack minHeight="100vh">
      <Header />

      <Container
        component="main"
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stack
          sx={{
            py: 15,
            mx: 'auto',
            maxWidth: 400,
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>

      <FooterCompact />
    </Stack>
  );
}

CompactLayout.propTypes = {
  children: PropTypes.node,
};
