import axios from 'src/utils/axios';

// PATIENT INSURANCE
export const getPatientInsurance = async (id) => {
  const response = await axios.get(`/api/Insurance/byPatient/${id}`);
  return response.data;
};

export const updatePatientInsurance = async (payload, id) => {
  const response = await axios.put(`/api/Insurance/${id}`, payload);
  return response.data;
};

export const createPatientInsurance = async (payload) => {
  const response = await axios.post('/api/Insurance', payload);
  return response.data;
};

// PROVIDERS
export const getInsuranceProviders = async () => {
  const response = await axios.get('/api/Insurance/provider');
  return response.data;
};

export const getInsuranceProvidersPublic = async () => {
  const response = await axios.get('/api/Insurance/provider/active');
  return response.data;
};

export const createInsuranceProviders = async (payload) => {
  const formData = new FormData();

  formData.append('providerName', payload.providerName);
  formData.append('providerNumber', payload.providerNumber);
  formData.append('address', payload.address);
  formData.append('rnc', payload.rnc);

  formData.append('Logo', payload.logo);
  formData.append('Template', payload.template);

  const response = await axios.post('/api/Insurance/provider', formData);
  return response;
};

export const UpdateInsuranceProviders = async (payload, id) => {
  const formData = new FormData();

  formData.append('providerName', payload.providerName);
  formData.append('providerNumber', payload.providerNumber);
  formData.append('address', payload.address);
  formData.append('rnc', payload.rnc);

  formData.append('Logo', payload.logo);
  formData.append('Template', payload.template);

  const response = await axios.put(`/api/Insurance/provider/${id}`, formData);
  return response;
};

// TEMPLATES
export const createTemplate = async (payload) => {
  const response = await axios.post('/insurance/Template', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

// CLAIMS
export const createInsuranceClaim = async (payload) => {
  const response = await axios.post('/api/Insurance/claims', payload);
  return response;
};

export const getInsuranceClaims = async () => {
  const response = await axios.get('/api/Insurance/claims');
  return response.data;
};

export const getInsuranceClaim = async (id) => {
  const response = await axios.get(`/api/Insurance/claims/${id}`);
  return response.data;
};

export const updateInsuranceClaim = async (payload, id) => {
  const response = await axios.put(`/api/Insurance/claims/${id}`, payload);
  return response;
};

export const getInsuranceClaimByProviderAudit = async (id, startTime, endTime) => {
  const startime = startTime.toISOString();
  const endtime = endTime.toISOString();
  const response = await axios.get(
    `/api/Insurance/claims/audit/${id}?startTime=${startime}&endTime=${endtime}`
  );
  return response.data;
};
