// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  MEDICORE: '/medicore',
  PATIENT: '/patient',
  PATIENT_PORTAL: '/patient-portal',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  medicore: {
    root: ROOTS.MEDICORE,
    admissions: `${ROOTS.MEDICORE}/admissions`,
    admissionDetails: (id) => `${ROOTS.MEDICORE}/admissions/${id}`,
    // DOCTORS
    consultingRooms: `${ROOTS.MEDICORE}/consultingRooms`,
    appointmentCalendar: `${ROOTS.MEDICORE}/appointmentCalendar`,
    consultingPatient: `${ROOTS.MEDICORE}/consultingPatient`,
    // EJEMPLO
    ejemplo: `${ROOTS.MEDICORE}/ejemplo`,
    // EMERGENCY
    emergency: {
      root: `${ROOTS.MEDICORE}/emergency`,
      editor: `${ROOTS.MEDICORE}/emergency/editor`,
    },
    // HOSPITALIZED
    hospitalized: `${ROOTS.MEDICORE}/hospitalized`,
    // INTENSIVE CARE UNIT
    intensiveCareUnit: `${ROOTS.MEDICORE}/intensiveCareUnit`,
    // LABORATORIES
    laboratories: `${ROOTS.MEDICORE}/laboratories`,
    // DEATHS
    deaths: `${ROOTS.MEDICORE}/deaths`,
    // ADMISSION REQUESTS
    admissionRequests: `${ROOTS.MEDICORE}/admissionRequests`,
    // ROOMS
    rooms: {
      root: `${ROOTS.MEDICORE}/rooms`,
      new: `${ROOTS.MEDICORE}/rooms/new`,
      list: `${ROOTS.MEDICORE}/rooms/list`,
    },
    // MEDICAL SERVICE CATALOG
    medicalServiceCatalog: {
      root: `${ROOTS.MEDICORE}/medicalServiceCatalog`,
    },
    // INVOICE-PATIENT
    patientInvoice: {
      root: `${ROOTS.MEDICORE}/patientInvoice`,
      new: `${ROOTS.MEDICORE}/patientInvoice/new`,
      list: `${ROOTS.MEDICORE}/patientInvoice/list`,
    },
    // INVENTORY
    inventory: {
      statistics: `${ROOTS.MEDICORE}/inventory/statistics`,
      list: `${ROOTS.MEDICORE}/inventory/list`,
      warehouse: `${ROOTS.MEDICORE}/inventory/warehouse`,
      listMedicalOrder: `${ROOTS.MEDICORE}/inventory/listMedicalOrder`,
      purchaseOrder: {
        root: `${ROOTS.MEDICORE}/inventory/purchaseOrder`,
        newPurchaseOrder: `${ROOTS.MEDICORE}/inventory/purchaseOrder/newPurchaseOrder`,
        listPurchaseOrder: `${ROOTS.MEDICORE}/inventory/purchaseOrder/listPurchaseOrder`,
      },
      requisition: {
        root: `${ROOTS.MEDICORE}/inventory/requisition`,
        newRequisition: `${ROOTS.MEDICORE}/inventory/requisition/newRequisition`,
        listRequisition: `${ROOTS.MEDICORE}/inventory/requisition/listRequisition`,
      },
      listProduct: `${ROOTS.MEDICORE}/inventory/listProduct`,
      listProvider: `${ROOTS.MEDICORE}/inventory/listProvider`,
      listCategory: `${ROOTS.MEDICORE}/inventory/listCategory`,
      dispatch: {
        root: `${ROOTS.MEDICORE}/inventory/dispatch`,
        statistics: `${ROOTS.MEDICORE}/inventory/dispatch/statistics`,
        dispatchProduct: `${ROOTS.MEDICORE}/inventory/dispatch/dispatchProduct`,
        dispatchWarehouse: `${ROOTS.MEDICORE}/inventory/dispatch/dispatchWarehouse`,
      },
    },
    // EMPLOYEE REQUEST
    employeeRequest: {
      root: `${ROOTS.MEDICORE}/employeeRequest`,
      request: `${ROOTS.MEDICORE}/employeeRequest/request`,
    },
    // FINANCE
    finance: {
      root: `${ROOTS.MEDICORE}/finance`,
      pos: `${ROOTS.MEDICORE}/finance/pos`,
      accounts: {
        root: `${ROOTS.MEDICORE}/finance/accounts`,
        list: `${ROOTS.MEDICORE}/finance/accounts/list`,
        new: `${ROOTS.MEDICORE}/finance/accounts/new`,
      },
      journal: {
        root: `${ROOTS.MEDICORE}/finance/journal`,
        list: `${ROOTS.MEDICORE}/finance/journal/list`,
        new: `${ROOTS.MEDICORE}/finance/journal/new`,
      },
      checks: {
        root: `${ROOTS.MEDICORE}/finance/checks`,
        list: `${ROOTS.MEDICORE}/finance/checks/list`,
        new: `${ROOTS.MEDICORE}/finance/checks/new`,
      },
      entry: {
        root: `${ROOTS.MEDICORE}/finance/entry`,
        list: `${ROOTS.MEDICORE}/finance/entry/list`,
      },
      fixedAssets: {
        root: `${ROOTS.MEDICORE}/finance/fixed-assets`,
      },
      loans: {
        root: `${ROOTS.MEDICORE}/finance/loans`,
      },
      invoices: {
        root: `${ROOTS.MEDICORE}/finance/invoices`,
        list: `${ROOTS.MEDICORE}/finance/invoices/list`,
      },
      pettyCash: {
        root: `${ROOTS.MEDICORE}/finance/petty-cash`,
      },
      payroll: {
        root: `${ROOTS.MEDICORE}/finance/payroll`,
      },
      report: {
        root: `${ROOTS.MEDICORE}/finance/report`,
      },
    },
    // NURSE
    nurse: {
      root: `${ROOTS.MEDICORE}/nurse`,
      shifts: `${ROOTS.MEDICORE}/nurse/shifts`,
      requestproducts: `${ROOTS.MEDICORE}/nurse/requestproducts`,
    },
    // INSURANCE
    insurance: {
      root: `${ROOTS.MEDICORE}/insurance`,
      providers: {
        root: `${ROOTS.MEDICORE}/insurance/providers`,
        list: `${ROOTS.MEDICORE}/insurance/providers/list`,
        new: `${ROOTS.MEDICORE}/insurance/providers/new`,
      },
      authorizations: {
        root: `${ROOTS.MEDICORE}/insurance/authorizations`,
        list: `${ROOTS.MEDICORE}/insurance/authorizations/list`,
        new: `${ROOTS.MEDICORE}/insurance/authorizations/new`,
      },
      audit: {
        root: `${ROOTS.MEDICORE}/insurance/audit`,
        list: `${ROOTS.MEDICORE}/insurance/audit/list`,
      },
    },
    // HUMAN RESOURCES
    humanResources: {
      root: `${ROOTS.MEDICORE}/humanResources`,
      employees: {
        root: `${ROOTS.MEDICORE}/humanResources/employees`,
        list: `${ROOTS.MEDICORE}/humanResources/employees/list`,
        new: `${ROOTS.MEDICORE}/humanResources/employees/new`,
      },
      departments: {
        root: `${ROOTS.MEDICORE}/humanResources/departments`,
      },
      jobPositions: {
        root: `${ROOTS.MEDICORE}/jobPositions`,
        new: `${ROOTS.MEDICORE}/jobPositions/new`,
        edit: (id) => `${ROOTS.MEDICORE}/jobPositions/edit/${id}`,
        details: (id) => `${ROOTS.MEDICORE}/jobPositions/details/${id}`,
      },
    },
  },

  // PATIENTS
  patient: {
    root: `${ROOTS.PATIENT}`,
    login: `${ROOTS.PATIENT}${ROOTS.PATIENT_PORTAL}/login`,
    appointments: `${ROOTS.PATIENT}${ROOTS.PATIENT_PORTAL}/appointments`,
    results: `${ROOTS.PATIENT}${ROOTS.PATIENT_PORTAL}/results`,
    digitalReport: (id) => `${ROOTS.PATIENT}${ROOTS.PATIENT_PORTAL}/digital-report/${id}`,
    list: `${ROOTS.PATIENT}/list`,
    new: `${ROOTS.PATIENT}/new`,
    edit: (id) => `${ROOTS.PATIENT}/edit/${id}`,
    rawing: `${ROOTS.PATIENT}/rawing`,
  },
};
