import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import FooterCompact from 'src/layouts/main/footer-compact';

import HeaderSimpleMedicore from '../common/header-simple-medicore';

// ----------------------------------------------------------------------

export default function PatientPortalProfileLayout({ children }) {
  return (
    <>
      <HeaderSimpleMedicore />

      <Box
        component="main"
        direction="row"
        sx={{
          minHeight: '100vh',
          position: 'relative',
          mt: 15,
          mb: 10,
        }}
      >
        {children}
      </Box>
      <FooterCompact />
    </>
  );
}

PatientPortalProfileLayout.propTypes = {
  children: PropTypes.node,
};
