import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import RoleBasedGuard from 'src/auth/guard/role-based-guard';

import { LoadingScreen } from 'src/components/loading-screen';

import { CheckListView } from 'src/sections/finance/checks/view';
import { JournalListView } from 'src/sections/finance/journal/view';

// ----------------------------------------------------------------------

const AccountCatalogPage = lazy(() => import('src/pages/medicore/finance/account-catalog-list'));
const PosViewPage = lazy(() => import('src/pages/medicore/pos/pos-page'));
const AccounttingListPage = lazy(() =>
  import('src/pages/medicore/finance/accounting-entry-list-view-page')
);
const AccountingEntryPage = lazy(() =>
  import('src/pages/medicore/finance/accounting-entry-add-view-page')
);
const FixedAssetsPage = lazy(() => import('src/pages/medicore/finance/fixed-assets-view-page'));
const InvoicesPage = lazy(() => import('src/pages/medicore/finance/invoices-view-list-page'));
const LoansPage = lazy(() => import('src/pages/medicore/finance/loans-list-view-page'));
const PayrollPage = lazy(() => import('src/pages/medicore/finance/payroll-view-list-page'));
const PettyCashPage = lazy(() => import('src/pages/medicore/finance/petty-cash-list-view-page'));
const ReportPage = lazy(() => import('src/pages/medicore/finance/report-view-page'));

// ----------------------------------------------------------------------

export const medicoreFinanceRoutes = [
  {
    path: 'medicore/finance',
    element: (
      <AuthGuard>
        <RoleBasedGuard roles={['doctor', 'employee']}>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RoleBasedGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'accounts',
        children: [{ path: 'list', element: <AccountCatalogPage /> }],
      },
      {
        path: 'journal',
        children: [{ path: 'list', element: <JournalListView /> }],
      },
      {
        path: 'checks',
        children: [{ path: 'list', element: <CheckListView /> }],
      },
      {
        path: 'pos',
        element: <PosViewPage />,
      },
      {
        path: 'entry',
        children: [
          { element: <AccountingEntryPage />, index: true },
          { path: 'list', element: <AccounttingListPage /> },
        ],
      },
      {
        path: 'fixed-assets',
        element: <FixedAssetsPage />,
      },
      {
        path: 'loans',
        element: <LoansPage />,
      },
      {
        path: 'invoices',
        children: [{ path: 'list', element: <InvoicesPage /> }],
      },
      {
        path: 'payroll',
        element: <PayrollPage />,
      },
      {
        path: 'petty-cash',
        element: <PettyCashPage />,
      },
      {
        path: 'report',
        element: <ReportPage />,
      },
    ],
  },
];
