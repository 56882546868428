import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export const getPatientDigitalReport = async (id) => {
  const response = await axios.get(`/api/Patient/DigitalRecord/${id}`);
  return response.data;
};

// ----------------------------------------------------------------------

export const getAllPatients = async () => {
  const response = await axios.get('/api/Patient');
  return response;
};


export const getAllAdmissionsByPatient = async (id) => {
  const response = await axios.get(`/api/Patient/admissions/${id}`);
  return response;
};

export const registerPatient = async (data) => {
  // Crear un objeto FormData
  const formData = new FormData();

  const birthDate = new Date(data.birth).toISOString().split('T')[0]; // Esto toma solo la parte de la fecha

  // Agregar datos textuales al formData
  formData.append('name', data.name);
  formData.append('lastname', data.lastname);
  formData.append('nationality', data.nationality);
  formData.append('birth', birthDate);
  formData.append('documentId', data.documentId);
  formData.append('documentType', data.documentType);
  formData.append('gender', data.gender);
  formData.append('city', data.city);
  formData.append('province', data.province);
  formData.append('addressLn1', data.addressLn1);
  formData.append('addressLn2', data.addressLn2);
  formData.append('country', data.country);
  formData.append('phoneNumber', data.phoneNumber);
  formData.append('cellNumber', data.cellNumber);
  formData.append('insuranceId', data.insuranceId);
  formData.append('planId', data.planId);
  formData.append('providerId', data.providerId);
  formData.append('expirationDate', data.expirationDate);
  formData.append('policyNumber', data.policyNumber);
  formData.append('biometricId', data.biometricId);
  formData.append('nss', data.nss);
  formData.append('roomId', data.roomId);
  formData.append('patientStatus', data.patientStatus);
  formData.append('action', data.action);

  // Agregar archivos al formData
  formData.append('cardIdFront', data.cardIdFront);
  formData.append('cardIdBack', data.cardIdBack);
  formData.append('insuranceFront', data.insuranceFront);
  formData.append('insuranceBack', data.insuranceBack);

  // Realizar la petición POST con axios
  try {
    const response = await axios.post('/api/Admission/createPatient', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Este encabezado es opcional, axios lo maneja automáticamente
      },
    });
    console.log(response.data); // Manejo exitoso
    return response.data;
  } catch (error) {
    console.error(error); // Manejo de errores
    throw error;
  }
};

export const getPatient = async (id) => {
  const response = await axios.get(`/api/Patient/${id}`);
  return response.data;
};

export const getPatientByFingerPrint = async (data) => {
  const response = await axios.post('/api/Biometric/GetPatientByFingerPrint', data);
  return response.data;
};

export const updatePatient = async (data) => {
  const response = await axios.put(`/api/Patient/${data.id}`, data);
  return response.data;
};

export const updatePatientContacInfo = async (data) => {
  const response = await axios.put(`/api/ContactInfo/${data.id}`, data);
  return response.data;
};

export const updatePatientInsurance = async (data) => {
  const response = await axios.put(`/api/Insurance/${data.id}`, data);
  return response.data;
};

export const updatePatientRepresentative = async (data) => {
  const response = await axios.post('/api/Patient/UpdatePatientRepresentative', data);
  return response.data;
};
