/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import axios, { endpoints } from 'src/utils/axios';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const decodeToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error('Invalid token', error);
    return null;
  }
};

const formatUserInfo = (userInfo) => {
  const {
    aud,
    exp,
    iss,
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata': userDataString,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname': givenName,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': name,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': nameIdentifier,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname': surname,
    ...other
  } = userInfo;

  // Si la propiedad userData está en formato JSON, intentamos parsearla
  const userData = userDataString ? JSON.parse(userDataString) : {};

  return {
    aud,
    exp,
    iss,
    givenName,
    name,
    nameIdentifier,
    surname,
    userData,
    other, // Aquí se agregan todas las propiedades adicionales que no se reconocieron.
  };
};

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.profile);
        const { userId, username, isPatient, isDoctor } = response.data;

        const userInfo = formatUserInfo(decodeToken(accessToken));

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              userId,
              username,
              accessToken,
              userInfo,
              isPatient,
              isDoctor,
              roles: isPatient ? ['patient'] : ['doctor', 'employee'],
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(
    async (user, password) => {
      const data = {
        username: user,
        password,
      };

      const response = await axios.post(endpoints.auth.login, data);
      const { jwt, userId, username, isPatient, isDoctor } = response.data;

      setSession(jwt);

      const userInfo = formatUserInfo(decodeToken(jwt));

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            userId,
            username,
            accessToken: jwt,
            userInfo,
            isPatient,
            isDoctor,
            roles: isPatient ? ['patient'] : ['doctor', 'employee'],
          },
        },
      });

      return { userId, username, isPatient, isDoctor, userInfo };
    },
    [dispatch]
  );


  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
