import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import CompactLayout from 'src/layouts/compact';
import { useAuthContext } from 'src/auth/hooks';
import { ForbiddenIllustration } from 'src/assets/illustrations';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { varBounce, MotionContainer } from 'src/components/animate';
// ----------------------------------------------------------------------

export default function RoleBasedGuard({ roles, children, sx }) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { user, logout } = useAuthContext();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('¡No se pudo cerrar la sesión!', { variant: 'error' });
    }
  };

  const currentRole = user?.roles;

  if (typeof roles !== 'undefined' && !currentRole?.some((role) => roles.includes(role))) {
    return (
      <CompactLayout>
        <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              No Autorizado
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              No tienes permisos para acceder a esta página.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration
              sx={{
                height: 260,
                my: { xs: 5, sm: 10 },
              }}
            />
          </m.div>
          <Button onClick={handleLogout} variant="contained" color="error" size="large">
            <Iconify icon="mdi:logout" height={24} width={24} />
            <Typography variant="h6" sx={{ ml: 1 }}>
              Cerrar sesión
            </Typography>
          </Button>
        </Container>
      </CompactLayout>
    );
  }

  return <> {children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
