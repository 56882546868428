import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, TextField, Autocomplete, CircularProgress } from '@mui/material';

import { getInsuranceProviders } from 'src/services/insurance/insurance-service-calls';

RHFProviderAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  providers: PropTypes.array,
  options: PropTypes.array,
};

export default function RHFProviderAutocomplete({ name, placeholder }) {
  const [providers, setProviders] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const methods = useFormContext();

  const handleChange = (event, newValue) => {
    methods.setValue(name, newValue, { shouldValidate: true });
  };

  const handleInputChange = (event, newInputValue) => {
    methods.setValue(`${name}SearchText`, newInputValue);
  };

  const hasError = !!methods.formState.errors[name];
  const selectedValue = methods.getValues(name);
  const selectedProvider = providers?.find((item) => item.insuranceProviderId === selectedValue);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        setLoading(true);
        const response = await getInsuranceProviders();
        setProviders(response);
        const providerIds = response.map((item) => item.insuranceProviderId || '');
        setOptions(providerIds);
      } catch (err) {
        setError('Error al cargar los proveedores');
        console.error('Error fetching providers:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchProviders();
  }, []);

  const getProviderByOption = (option) =>
    providers.find((item) => item.insuranceProviderId === option);

  return (
    <Autocomplete
      fullWidth
      value={methods.watch(name)}
      options={options}
      onChange={handleChange}
      loading={loading}
      inputValue={methods.watch(`${name}SearchText`) || ''}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => {
        if (!option || !providers?.length) return '';
        const provider = getProviderByOption(option);
        return provider?.providerName || '';
      }}
      renderInput={(params) => (
        <Box sx={{ position: 'relative' }}>
          {selectedProvider?.logo && (
            <Box
              component="img"
              src={`data:image/svg+xml;base64,${selectedProvider.logo.file}`}
              alt={selectedProvider.providerName}
              sx={{
                position: 'absolute',
                left: 12,
                top: '50%',
                transform: 'translateY(-50%)',
                width: 45,
                height: 45,
                zIndex: 1,
              }}
            />
          )}
          <TextField
            {...params}
            error={hasError || !!error}
            helperText={hasError ? methods.formState.errors[name]?.message : error}
            label={placeholder}
            required
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
              sx: {
                ...(selectedProvider?.logo && {
                  '& .MuiInputBase-input': {
                    paddingLeft: '59px !important',
                  },
                }),
              },
            }}
          />
        </Box>
      )}
      renderOption={(props, option) => {
        if (!providers?.length) return null;
        const provider = getProviderByOption(option);
        if (!provider?.providerName) return null;

        return (
          <li
            {...props}
            key={`provider-${provider.insuranceProviderId}`}
            role="option"
            aria-selected={selectedValue === provider.insuranceProviderId}
          >
            {provider.logo && (
              <img
                src={`data:image/svg+xml;base64,${provider.logo.file}`}
                alt={provider.providerName}
                width={50}
                height={50}
                style={{ marginRight: 8 }}
                loading="lazy"
              />
            )}
            {provider.providerName}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => {
        if (!option || !value) return false;
        return option === value;
      }}
      noOptionsText="No se encontraron proveedores"
      loadingText="Cargando..."
      clearText="Limpiar"
      openText="Abrir"
      closeText="Cerrar"
    />
  );
}
