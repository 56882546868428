import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Logo from 'src/components/logo/logo-mini';

// ----------------------------------------------------------------------

FooterCompact.propTypes = {
  sx: PropTypes.object,
};

export default function FooterCompact({ sx }) {
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        ...sx,
      }}
    >
      <Divider />

      <Box
        component="footer"
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <Container>
          <Typography
            variant="caption"
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.2,
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            © {new Date().getFullYear()}
            <Logo sx={{ width: 25, height: 25 }} />
            Medicore®. Todos los derechos reservados.
          </Typography>
          <Typography variant="caption" component="div">
            Hecho por
            <Link href="https://nexuscore.com.do/"> nexus core crh </Link>
          </Typography>
        </Container>
      </Box>
    </Box>
  );

  return mainFooter;
}
