import { saveAs } from 'file-saver';

export const useFileHandler = () => {
  const downloadFile = (base64Data, fileType, fileName) => {
    try {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      [...byteCharacters].forEach((char, i) => {
        byteNumbers[i] = char.charCodeAt(0);
      });

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: fileType });

      // Get file extension from fileType
      const extension = fileType.split('/')[1];
      const fullFileName = `${fileName}.${extension}`;

      saveAs(blob, fullFileName);

      return {
        success: true,
        message: 'Archivo descargado exitosamente',
      };
    } catch (error) {
      console.error('Error downloading file:', error);
      return {
        success: false,
        message: 'Error al descargar el archivo',
        error,
      };
    }
  };

  return {
    downloadFile,
  };
};
