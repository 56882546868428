import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import RoleBasedGuard from 'src/auth/guard/role-based-guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const InsuranceProviders = lazy(() =>
  import('src/sections/insurance/insurance-providers/view/insurance-providers-list-view')
);
const AuthorizationsListView = lazy(() =>
  import('src/sections/insurance/insurance-providers/authorizations-list-screen')
);
const InsuranceAuditPage = lazy(() => import('src/pages/medicore/insurance/insurance-audit'));

// ----------------------------------------------------------------------

export const medicoreInsuranceRoutes = [
  {
    path: 'medicore/insurance',
    element: (
      <AuthGuard>
        <RoleBasedGuard roles={['doctor', 'employee']}>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RoleBasedGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'providers',
        children: [{ path: 'list', element: <InsuranceProviders /> }],
      },
      {
        path: 'authorizations',
        children: [{ path: 'list', element: <AuthorizationsListView /> }],
      },
      {
        path: 'audit',
        children: [{ path: 'list', element: <InsuranceAuditPage /> }],
      },
    ],
  },
];
