import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import FooterCompact from 'src/layouts/main/footer-compact';

import Header from '../common/header-simple';

// ----------------------------------------------------------------------

export default function PatientCompactLayout({ children }) {
  return (
    <>
      <Header />

      <Box
        component="main"
        sx={{
          py: 5,
          display: 'flex',
          minHeight: '100vh',
          textAlign: 'center',
          px: { xs: 2, md: 0 },
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          '&:before': {
            width: 1,
            height: 1,
            zIndex: -1,
            content: "''",
            opacity: 0.5,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: 'url(/assets/background/compact_portal_patient_overlay.jpg)',
          },
        }}
      >
        {children}
      </Box>
      <FooterCompact sx={{ bgcolor: '#97b7d1' }} />
    </>
  );
}

PatientCompactLayout.propTypes = {
  children: PropTypes.node,
};
