import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import RoleBasedGuard from 'src/auth/guard/role-based-guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const HomePage = lazy(() => import('src/pages/medicore/home'));
const CreateRoomPage = lazy(() => import('src/pages/medicore/rooms/new'));
const ListRoomPage = lazy(() => import('src/pages/medicore/rooms/list'));

const ListPatientInvoicePage = lazy(() => import('src/pages/medicore/patient-invoices/list'));
const CreatePatientInvoicePage = lazy(() => import('src/pages/medicore/patient-invoices/new'));
const HospitalizedEditorPage = lazy(() => import('src/pages/medicore/hospitalized/hospitalized'));
const IntensiveCareUnitEditorPage = lazy(() =>
  import('src/pages/medicore/intensive-care-unit/icu')
);
const LaboratoryEditorPage = lazy(() => import('src/pages/medicore/laboratories/laboratories'));
const AdmissionRequestPage = lazy(() =>
  import('src/pages/medicore/admission-requests/admission-requests')
);
const DeathsEditorPage = lazy(() => import('src/pages/medicore/deaths/deaths'));
const EjemploPage = lazy(() => import('src/pages/medicore/ejemplo/ejemplo'));
const AdmissionListView = lazy(() => import('src/pages/medicore/admissions/admissions-list-view'));
const AdmissionDetailsView = lazy(() =>
  import('src/pages/medicore/admissions/admissions-details-view')
);
const NurseShiftsPage = lazy(() => import('src/pages/medicore/nurse/shift-details-view'));
const NurseRequestProductsPage = lazy(() =>
  import('src/pages/medicore/nurse/product-request-view')
);
const MedicalServiceCatalogPage = lazy(() =>
  import('src/pages/medicore/medical-service-catalog/medical-service-page')
);

// ----------------------------------------------------------------------

/**
 * Routes configuration for the Medicore section of the application.
 *
 * @constant {Array} medicoreRoutes - Array of route objects.
 * @property {string} path - The path for the route.
 * @property {JSX.Element} element - The component to render for the route.
 * @property {Array} children - Nested routes configuration.
 *
 * @example
 * // Example usage:
 * import { medicoreRoutes } from './path/to/medicore';
 *
 * <Router>
 *   {medicoreRoutes.map((route, index) => (
 *     <Route key={index} path={route.path} element={route.element}>
 *       {route.children && route.children.map((child, childIndex) => (
 *         <Route key={childIndex} path={child.path} element={child.element} />
 *       ))}
 *     </Route>
 *   ))}
 * </Router>
 */
export const medicoreRoutes = [
  {
    path: 'medicore',
    element: (
      <AuthGuard>
        <RoleBasedGuard roles={['doctor', 'employee']}>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RoleBasedGuard>
      </AuthGuard>
    ),
    children: [
      { element: <HomePage />, index: true },
      { path: 'ejemplo', element: <EjemploPage /> },
      { path: 'admissions', element: <AdmissionListView /> },
      { path: 'admissions/:id', element: <AdmissionDetailsView /> },
      {
        path: 'rooms',
        children: [
          { element: <ListRoomPage />, index: true },
          { path: 'new', element: <CreateRoomPage /> },
          { path: 'list', element: <ListRoomPage /> },
        ],
      },
      {
        path: 'medicalServiceCatalog',
        element: <MedicalServiceCatalogPage />,
      },
      {
        path: 'patientInvoice',
        children: [
          { element: <ListPatientInvoicePage />, index: true },
          { path: 'new', element: <CreatePatientInvoicePage /> },
          { path: 'list', element: <ListPatientInvoicePage /> },
        ],
      },
      {
        path: 'nurse',
        children: [
          { element: <NurseShiftsPage />, index: true },
          { path: 'shifts', element: <NurseShiftsPage /> },
          { path: 'requestproducts', element: <NurseRequestProductsPage /> },
        ],
      },
      { path: 'hospitalized', element: <HospitalizedEditorPage /> },
      { path: 'intensiveCareUnit', element: <IntensiveCareUnitEditorPage /> },
      { path: 'laboratories', element: <LaboratoryEditorPage /> },
      { path: 'deaths', element: <DeathsEditorPage /> },
      { path: 'admissionRequests', element: <AdmissionRequestPage /> },
    ],
  },
];
