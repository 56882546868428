/**
 * Formats a Dominican ID (cédula) string to the pattern 999-9999999-9
 * @param {string} cedula - The identification number to format
 * @returns {string} - Formatted identification or original string if invalid
 */
export const formatCedula = (cedula) => {
  if (!cedula) return '';

  // Remove any non-digit character
  const cleanedCedula = cedula.replace(/\D/g, '');

  // Check if we have exactly 11 digits
  if (cleanedCedula.length !== 11) {
    return cedula;
  }

  // Format as XXX-XXXXXXX-X
  return `${cleanedCedula.slice(0, 3)}-${cleanedCedula.slice(3, 10)}-${cleanedCedula.slice(10)}`;
};
